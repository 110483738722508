import request from '../../utils/request'

interface Combo<T> {
  name?: T;
}
//获取套餐数据
export function getPackageTypeFullList(data?: Combo<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/JkglCheckPackageType/fullList',
    type: 'params',
    data
  })
}
//根据套餐节点id进行删除操作
export function deleteType(data: any): Promise<any> {
  return request({
    url: 'manage/JkglCheckPackageType',
    method: 'DELETE',
    type: 'params',
    data
  })
}
export function submitType(data: any): Promise<any> {
  return request({
    url: 'manage/JkglCheckPackageType/submit',
    method: 'POST',
    data
  })
}
