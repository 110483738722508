



































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  getComboData,
  deleteCombo,
  getComboItem,
  updateCombo,
  updateIsEmphasisProject,
  submit
} from "@/serve/medicalAppointment/comboManage";
import { getPackageTypeFullList } from "@/serve/medicalAppointment/packageType";
import { getDiseaseList } from "@/serve/evaluatingBefore/evaluatingStandard";
import { getTenantData } from "@/serve/medicalAppointment/institutionManage";
interface FormData {
  topic: string;
  standardType: string;
  sort: number | string | undefined;
  topicType: string;
  more: string;
  [index: string]: any;
}
@Component({
  name: "ComboManage",
})
export default class ComboManage extends Vue {
  public form = {
    packageName: "",
    packageFit: "",
    tenantId: "",
  };
  public page = {
    current: 1,
    size: 10,
  };
  public diseaseName = "";
  public itemData = [];
  public packageDesc = ""
  public comboData = [];
  public total = 0;
  public itemDialog = false;
  public diseaseDate = [];
  public isAdmin = sessionStorage.getItem("isAdmin");
  public tenantName = "";
  public restaurants = [];
  public packageId = 0;
  public created() {
    this.fetchPackageTypeList();
    this.getComboData();
  }

  /**获取全部租户 */
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = "";
  }
  public async changeSwitch(e: any) {
    console.log("e", e);
    const params = {
      checkItemIds: [e.id],
      packageId: this.packageId,
    };
    const res = await updateIsEmphasisProject(params);

    if (res.code === 200) {
      this.$message({
        message: "更新成功",
        type: "success",
      });
    }
  }
  public async submitCheckItemDesc(){
    const params = {
      packageDesc:this.packageDesc,
      id: this.packageId,
    };
    const res = await submit(params);

    if (res.code === 200) {
      this.$message({
        message: "更新成功",
        type: "success",
      });
    }
    this.getComboData();
    this.itemDialog = false
    

  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  //获取套餐表数据
  public async getComboData(): Promise<void> {
    // 获取套餐列表数据
    const dataItem = { ...this.form, ...this.page };
    const res = await getComboData(this.$changNull(dataItem));
    this.comboData = res.data.records;
    // comboData通过diseaseId添加对应的diseaseName
    this.comboData.forEach((ele: any, index) => {
      if (ele.packageTypeId === -1) {
        ele.packageTypeId = null;
      }
      this.diseaseDate.forEach((val: any) => {
        if (ele.diseaseId === val.id) {
          this.$set(this.comboData[index], "diseaseName", val.diseaseName);
        }
      });
    });
    // console.log('comboData :>> ', this.comboData);
    this.total = res.data.total;
  }
  public async lookItems(row: { id: number;packageDesc: string }) {
    this.packageId = row.id;
    console.log("row",row);
    
    this.packageDesc = row.packageDesc;
    const res = await getComboItem(row.id);
    this.itemData = res.data;
    
    this.itemDialog = true;
  }
  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getComboData();
  }
  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getComboData();
  }
  // 搜索
  public async search() {
    // const value = { ...this.page, ...this.form };
    // const res = await getComboData(this.$changNull(value));
    // this.comboData = res.data.records;
    // this.total = res.data.total;
    this.getComboData();
  }
  //多选删除
  public handleSelectionChange() {
    const list = (this.$refs.combo as any).selection;
    this.deleteRow(list);
  }
  //删除
  public deleteRow(list: { id: number }[]): void {
    (this as any).$messageBox
      .confirm("您确定是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const ids: number[] = [];
        list.forEach((ele) => {
          ids.push(ele.id);
        });
        const res = await deleteCombo(ids);
        if (res.success) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getComboData();
        } else {
          this.$message.error("删除失败" + res.msg);
        }
      });
  }
  // 关联疾病系统
  public async getDiseaseName(diseaseId: number, row: any) {
    diseaseId = diseaseId ? diseaseId : 0;
    const data = {
      id: row.id,
      diseaseId: diseaseId,
    };
    const res = await updateCombo(data);
    if (res.success) {
      if (diseaseId === 0) {
        this.$message({
          message: row.packageName + "未关联疾病系统",
          type: "warning",
        });
      } else {
        this.$message({
          message: row.packageName + "关联疾病系统成功",
          type: "success",
        });
      }
      this.getComboData();
    } else {
      this.$message.error(res.msg);
    }
  }
  public async changeType(typeId: number, row: any) {
    console.log(typeId, "");
    const data = {
      id: row.id,
      packageTypeId: typeId,
    };
    const res = await updateCombo(data);
    if (res.success) {
      this.$message({
        message: row.packageName + "关联成功",
        type: "success",
      });
      this.getComboData();
    } else {
      this.$message.error(res.msg);
    }
  }
  private async openDisease(e: any, row: { packageTenantId: any }) {
    if (e) {
      this.diseaseDate = [];
      const { packageTenantId } = row;
      const data = { tenantId: packageTenantId, current: 1, size: 50 };
      const diseaseDate = await getDiseaseList(data);
      this.diseaseDate = diseaseDate.data.records;
    }
  }
  private packageTypeList = [];
  private fetchPackageTypeList() {
    getPackageTypeFullList().then((res: any) => {
      this.packageTypeList = res.data;
    });
  }
  // 重置功能
  public resetForm() {
    this.tenantName = "";
    this.form = {
      packageName: "",
      packageFit: "",
      tenantId: "",
    };
    this.page = {
      current: 1,
      size: 10,
    };
    this.getComboData();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = "";
  }
}
