import request from '../../utils/request'

//获取评估标准接口分页
export function getDiseaseList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/disease/list'
  })
}

// 获取评估标准详情数据
export function getDetailDisease(id: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/disease/detail/  ' + id
  })
}

interface DiseaseStandardList {
  comment: string;
  highestScore: number | string;
  lowestScore: number | string;
  remark: string;
  riskLevel: string;
  [propName: string]: any;
}
interface PostDetail {
  diseaseName: string;
  diseaseType: string;
  diseaseStandardList: DiseaseStandardList[];
  [propName: string]: any;
}
// 新增修改评估标准
export function postDetailDisease(data: PostDetail): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/disease/submit'
  })
}

// 删除评估标准
export function deleteDetail(ids: number): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/disease/remove?ids=' + ids
  })
}

// // 获取详情标准
export function getOneDetail(ids: number): Promise<any> {
  return request({
    method: 'GET',
    url: '/manage/disease/detail/' + ids
  })
}


//获取问卷内容
export function getQuestionnaireList(data: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/questionnaire/itemPageListByType?type='+data.type,
  })
}

// 答案分页
export function getItemById(data: any): Promise<any> {
  return request({
    method: 'GET',
    data: data,
    type: 'params',
    url: 'manage/questionnaire/answerPageList'
  })
}

//获取问卷id
export function getQuestionnaireId(data: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/questionnaire/pageList?type='+data.type
  })
}

//新增问题
export function addNewQestion(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/questionnaire/addItem'
  })
}

//删除问题
export function deleteQestion(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/questionnaire/deleteItem'
  })
}

//修改问题
export function updateQestion(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/questionnaire/updateItem'
  })
}

//新增选项
export function addSelect(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/questionnaire/addAnswer'
  })
}

//修改选项
export function updateSelect(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/questionnaire/updateAnswer'
  })
}

//删除选项
export function deleteSelect(data: any): Promise<any> {
  return request({
    method: 'POST',
    data: data,
    url: 'manage/questionnaire/deleteAnswer'
  })
}

//获取满意度问卷统计
export function getSatisfactionStatistics(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/questionnaire/satisfactionResultCount'
  })
}